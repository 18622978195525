import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/shared/services/config.service';

@Component({
  selector: 'app-data-protection-page',
  templateUrl: './data-protection-page.component.html',
  styleUrls: ['./data-protection-page.component.css']
})
export class DataProtectionPageComponent implements OnInit {

  data_protection$: Observable<any> = new Observable();

  constructor(private config: ConfigService) {}

  ngOnInit() {
    this.getPageData("pages", 13);
  }

  getPageData(database: string, id?: number) {
    this.data_protection$ = this.config.getSettings(database, id);
  }

}
