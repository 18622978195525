import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { EventProtectionPageComponent } from "./event-protection-page/event-protection-page.component";

const routes: Routes = [{ path: "", component: EventProtectionPageComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EventProtectionRoutingModule {}
