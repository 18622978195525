import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { EventProtectionPageComponent } from "./event-protection-page/event-protection-page.component";
import { EventProtectionRoutingModule } from "./event-protection-routing.module";



@NgModule({
  declarations: [EventProtectionPageComponent],
  imports: [CommonModule, EventProtectionRoutingModule],
})
export class EventProtectionModule {}
