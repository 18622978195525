import { Observable } from 'rxjs';
import { Component, OnInit } from "@angular/core";
import { ConfigService } from "../../shared/services/config.service";

@Component({
  selector: "app-impressum-page",
  templateUrl: "./impressum-page.component.html",
  styleUrls: ["./impressum-page.component.css"],
})
export class ImpressumPageComponent implements OnInit {
  impressum$: Observable<any> = new Observable();

  constructor(private config: ConfigService) {}

  ngOnInit() {
    this.getPageData("pages", 5);
  }

  getPageData(database: string, id?: number) {
    this.impressum$ = this.config.getSettings(database, id);
  }
}
