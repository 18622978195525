import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BodyguardRoutingModule } from './bodyguard-routing.module';
import { BodyguardPageComponent } from './bodyguard-page/bodyguard-page.component';
import { FormsModule } from '@angular/forms';

// bodyguard-page.component
@NgModule({
    declarations: [
      BodyguardPageComponent
    ],
    imports: [
        CommonModule,
        BodyguardRoutingModule
    ]
})
export class BodyguardModule { }
