import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/shared/services/config.service';

@Component({
  selector: 'app-city-patrol-page',
  templateUrl: './city-patrol-page.component.html',
  styleUrls: ['./city-patrol-page.component.css']
})
export class CityPatrolPageComponent implements OnInit {

  city_patrol$: Observable<any> = new Observable();

  constructor(private config: ConfigService) {}

  ngOnInit() {
    this.getPageData("pages", 10);
  }

  getPageData(database: string, id?: number) {
    this.city_patrol$ = this.config.getSettings(database, id);
  }

}
