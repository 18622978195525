import { Observable } from 'rxjs';
import { Component, OnInit, Inject, Input, HostListener } from "@angular/core";
import { ConfigService } from "../../shared/services/config.service";
import { DOCUMENT } from '@angular/common'
import { NavigationEnd, NavigationStart, Router, RouterEvent } from '@angular/router';


@Component({
  selector: "app-services-page",
  templateUrl: "./services-page.component.html",
  styleUrls: ["./services-page.component.css"],
})
export class ServicesPageComponent implements OnInit {
  images$: Observable<any> = new Observable();
  header$: Observable<any> = new Observable();

  // images: {
  //   id: number;
  //   name: string;
  // }[];

  constructor(private config: ConfigService) {}

  ngOnInit() {
    this.getBlockData("images");
    this.getPageData("pages", 4);
  }

  getBlockData(database: string) {
    this.images$ = this.config.getSettings(database);
  }

  getPageData(database: string, id?: number): void {
    this.header$ = this.config.getSettings(database, id);
  }


}
