// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  SERVICE_ID: "service_ezq7l2n",
  TEMPLATE_ID: "template_bndq6a8",
  PUBLIC_KEY: "zLwnX5snIDtRfITAj",
  recaptcha: {
    siteKey: "6LcJkRwjAAAAAFXzO4ChoCp5GuEf9qvzy6PF5RGs"
  }

};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
