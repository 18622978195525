import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataProtectionPageComponent } from './data-protection-page/data-protection-page.component';
import { DataProtectionRoutingModule } from './data-protection-routing.module';

@NgModule({
  declarations: [
    DataProtectionPageComponent
  ],
  imports: [
    CommonModule,
    DataProtectionRoutingModule
  ]
})
export class DataProtectionModule { }
