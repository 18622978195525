import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CityPatrolRoutingModule } from "./city-patrol-routing.module";
import { CityPatrolPageComponent } from "./city-patrol-page/city-patrol-page.component";


@NgModule({
  declarations: [CityPatrolPageComponent],
  imports: [CommonModule, CityPatrolRoutingModule],
})
export class CityPatrolModule {}
