import { BrowserModule } from "@angular/platform-browser";
import { CommonModule } from '@angular/common';
import { NgModule } from "@angular/core";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpClientInMemoryWebApiModule } from "angular-in-memory-web-api";
import { ReactiveFormsModule } from "@angular/forms";

// Components
import { AppComponent } from "./app.component";
import { FooterComponent } from "./footer/footer.component";
import { NavmenuComponent } from "./navmenu/navmenu.component";
import { NavigationComponent } from './navigation/navigation.component';
import { SocialComponent } from './social/social.component';
import { WebsiteBlockComponent } from "./website-block/website-block.component";

// Modules
import { AppRoutingModule } from "./app-routing.module";
import { HomeModule } from "./home/home.module";
import { NotfoundModule } from "./notfound/notfound.module";
import { ContactModule } from "./contact/contact.module";
import { ServicesModule } from "./services/services.module";
import { ImpressumModule } from "./impressum/impressum.module";

// Services
import { ConfigService } from "./shared/services/config.service";
import { InMemoryDataService } from "./shared/services/in-memory-data.service";


import { FormsModule } from '@angular/forms';
import { RECAPTCHA_SETTINGS, RecaptchaSettings, RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module, RecaptchaFormsModule, RecaptchaModule } from 'ng-recaptcha';

import { environment } from '../environments/environment';
import { BodyguardModule } from "./services-pages-separat/bodyguard/bodyguard.module";
import { ReceptionPorterServiceModule } from "./services-pages-separat/reception-porter-service/reception-porter-service.module";
import { ObjectProtectionModule } from "./services-pages-separat/object-protection/object-protection.module";
import { MobileSecurityServicesModule } from "./services-pages-separat/mobile-security-services/mobile-security-services.module";
import { CityPatrolModule } from "./services-pages-separat/city-patrol/city-patrol.module";
import { EventProtectionModule } from "./services-pages-separat/event-protection/event-protection.module";
import { DataProtectionModule } from "./data-protection/data-protection.module";

import { MatDialogModule } from '@angular/material/dialog';

import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { MatNativeDateModule } from "@angular/material/core";
import { ModalDialogOverview } from "./contact/contact-modal/contact-modal.component";
import { MatFormFieldModule } from '@angular/material/form-field';
import { MaterialModule } from "src/material.module";
import { SpinnerComponent } from './spinner/spinner.component';
import { LoadingInterceptor } from "./loading.interceptor";
import { CareersModule } from "./careers/careers.module";


@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    NavigationComponent,
    NavmenuComponent,
    SocialComponent,
    WebsiteBlockComponent,
    ModalDialogOverview,
      SpinnerComponent
   ],
  imports: [
    BrowserModule,
    CommonModule,
    HomeModule,
    ImpressumModule,
    AppRoutingModule,
    HttpClientModule,
    NotfoundModule,
    ContactModule,
    ReactiveFormsModule,
    ServicesModule,
    FormsModule,
    BodyguardModule,
    ReceptionPorterServiceModule,
    ObjectProtectionModule,
    MobileSecurityServicesModule,
    CityPatrolModule,
    EventProtectionModule,
    DataProtectionModule,
    MatDialogModule,
    BrowserAnimationsModule,
    MatNativeDateModule,
    MaterialModule,
    RecaptchaV3Module,
    RecaptchaFormsModule,
    RecaptchaModule,
    CareersModule,
    // The HttpClientInMemoryWebApiModule module intercepts HTTP requests
    // and returns simulated server responses.
    // Remove it when a real server is ready to receive requests.
    HttpClientInMemoryWebApiModule.forRoot(InMemoryDataService, {
      dataEncapsulation: false,
      passThruUnknownUrl: true,
    })
  ],
  providers: [
    ConfigService,
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: "6LcJkRwjAAAAAFXzO4ChoCp5GuEf9qvzy6PF5RGs"
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ ModalDialogOverview ]
})
export class AppModule {}
