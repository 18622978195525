import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImageBlockComponent } from '../services/image-block/image-block.component';
import { ContactPageComponent } from './contact-page/contact-page.component';

const routes: Routes = [{
  path: '', component: ContactPageComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ContactRoutingModule { }
