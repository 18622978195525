import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from "@angular/router";
import { PreloadAllModules } from '@angular/router';
import {QuicklinkStrategy, QuicklinkModule} from 'ngx-quicklink';


const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },

  {
    path: "home",
    loadChildren: () =>
      import("./home/home.module").then((mod) => mod.HomeModule),
  },
  {
    path: "services",
    loadChildren: () =>
      import("./services/services.module").then((mod) => mod.ServicesModule),
  },
  {
    path: "contact",
    loadChildren: () =>
      import("./contact/contact.module").then((mod) => mod.ContactModule),
  },
  {
    path: "careers",
    loadChildren: () =>
      import("./careers/careers.module").then((mod) => mod.CareersModule),
  },
  {
    path: "impressum",
    loadChildren: () =>
      import("./impressum/impressum.module").then((mod) => mod.ImpressumModule),
  },
  {
    path: "services/bodyguard",
    loadChildren: () =>
      import("./services-pages-separat/bodyguard/bodyguard.module").then((mod) => mod.BodyguardModule),
    pathMatch: "full"
  },
  {
    path: "services/reception-porter-service",
    loadChildren: () =>
      import("./services-pages-separat/reception-porter-service/reception-porter-service.module").then((mod) => mod.ReceptionPorterServiceModule),
    pathMatch: "full"
  },
  {
    path: "services/object-protection",
    loadChildren: () =>
      import("./services-pages-separat/object-protection/object-protection.module").then((mod) => mod.ObjectProtectionModule),
    pathMatch: "full"
  },
  {
    path: "services/mobile-security-services",
    loadChildren: () =>
      import("./services-pages-separat/mobile-security-services/mobile-security-services.module").then((mod) => mod.MobileSecurityServicesModule),
    pathMatch: "full"
  },
  {
    path: "services/city-patrol",
    loadChildren: () =>
      import("./services-pages-separat/city-patrol/city-patrol.module").then((mod) => mod.CityPatrolModule),
    pathMatch: "full"
  },
  {
    path: "services/event-protection",
    loadChildren: () =>
      import("./services-pages-separat/event-protection/event-protection.module").then((mod) => mod.EventProtectionModule),
    pathMatch: "full"
  },
  {
    path: "data-protection",
    loadChildren: () =>
      import("./data-protection/data-protection.module").then((mod) => mod.DataProtectionModule),
    pathMatch: "full"
  },
  {
    path: "404",
    loadChildren: () =>
      import("./notfound/notfound.module").then((mod) => mod.NotfoundModule),
  },
  { path: "**", redirectTo: "/404" },
];

@NgModule({
  imports: [CommonModule, QuicklinkModule, RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule],
  declarations: [],
})
export class AppRoutingModule { }


// ,
//     {
//       preloadingStrategy: QuicklinkStrategy
//     }
