import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DataProtectionPageComponent } from "./data-protection-page/data-protection-page.component";


const routes: Routes = [
  {
    path: "",
    component: DataProtectionPageComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class DataProtectionRoutingModule {}
