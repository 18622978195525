import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MobileSecurityServicesPageComponent } from './mobile-security-services-page/mobile-security-services-page.component';
import { MobileSecurityServicesRoutingModule } from './mobile-security-services-routing.module';

@NgModule({
  declarations: [
    MobileSecurityServicesPageComponent
  ],
  imports: [
      CommonModule,
      MobileSecurityServicesRoutingModule
  ]
})
export class MobileSecurityServicesModule { }
