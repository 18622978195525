import { Observable } from 'rxjs';
import { Component, OnInit } from "@angular/core";
import { ConfigService } from "../../../shared/services/config.service";

@Component({
  selector: 'app-reception-porter-service-page',
  templateUrl: './reception-porter-service-page.component.html',
  styleUrls: ['./reception-porter-service-page.component.css']
})
export class ReceptionPorterServicePageComponent implements OnInit {

  reception_service$: Observable<any> = new Observable();
  // page_bullet_points$: Observable<any> = new Observable();

  constructor(private config: ConfigService) {}

  ngOnInit() {
    this.getPageData("pages", 7);
    // this.getBulletPoints("page_bullet_points", 7);
  }

  getPageData(database: string, id?: number) {
    this.reception_service$ = this.config.getSettings(database, id);
  }

  // getBulletPoints(database: string, id?: number) {
  //   this.page_bullet_points$ = this.config.getSettings(database, id);
  // }


}
