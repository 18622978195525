import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ReceptionPorterServicePageComponent } from './reception-porter-service-page/reception-porter-service-page.component';


const routes: Routes = [{
  path: '', component: ReceptionPorterServicePageComponent
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReceptionPorterServiceRoutingModule { }
