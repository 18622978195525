import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImpressumPageComponent } from './impressum-page/impressum-page.component';
import { ImpressumRoutingModule } from './impressum-routing.module';

@NgModule({
  declarations: [
    ImpressumPageComponent
  ],
  imports: [
    CommonModule,
    ImpressumRoutingModule
  ]
})
export class ImpressumModule { }
