import { Component, Input, OnInit, Inject } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';

export interface DialogData {
  resultText: string;
  infoText: string;
}

@Component({
	selector: 'ngbd-modal-content',
	templateUrl: './contact-modal.component.html',
  styleUrls: ['./contact-modal.component.css'],
})
export class ModalDialogOverview {
	@Input() result;
	@Input() infoText;

  constructor(public dialogRef: MatDialogRef<ModalDialogOverview>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData) {}

  onOkClick(): void {
    this.dialogRef.close();
  }
}


