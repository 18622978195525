import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Injectable, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Contact } from 'src/app/core/models';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient, HttpParams } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDialogOverview } from '../contact-modal/contact-modal.component';

export interface DialogData {
  resultText: string;
  infoText: string;
}

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactPageComponent implements OnInit, OnChanges {

  @Input() contact: Contact = {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  };

  @Output() save = new EventEmitter<Contact>();

  contactForm: FormGroup;
  handleUpdateResponse: any;
  handleError: any;
  dialogTitle: string;
  dialogText: string;
  closeResult: string;

  constructor(public formBuilder: FormBuilder, public http: HttpClient, public dialog: MatDialog, private modalService: NgbModal) {

  }

  // resolved(captchaResponse: string) {
  //   console.log(`Resolved captcha with response: ${captchaResponse}`);
  // }

  ngOnInit() {
    this.contactForm = this.formBuilder.group({
      firstname: [this.contact.firstname, Validators.required],
      lastname: [this.contact.lastname, Validators.required],
      email: [this.contact.email, Validators.pattern('^[a-zA-Z]{1,}[a-zA-Z0-9.\-_]{1,}@[a-zA-Z]{1}[a-zA-Z.-]*[a-zA-Z]{1}[.][a-zA-Z]{2,}$')],
      phone: [this.contact.phone],
      subject: [this.contact.subject, Validators.required],
      message: [this.contact.message, Validators.required],
      recaptcha: [null, Validators.required],
      checkbox: [null, Validators.required]
    });
  }

  ngOnChanges() {
    if (this.contact) {
      this.contactForm.patchValue({ ...this.contact });
    }
  }

  async sendEmail(body: HttpParams) {

    await this.http.post("https://mail-api-for-uss.azurewebsites.net/api/sendmail", body.toString(), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    })
      .subscribe(
        (res: any) => {
          console.log("res");
          console.log(res);
          console.log("res.statusCode");
          this.openDialog(res.status)
        },
        err => console.log(err)
      );
  }


  async submit() {
    if (this.contactForm.valid) {
      this.save.emit(this.contactForm.value);

      const firstname = this.contactForm.get('firstname').value
      const lastname = this.contactForm.get('lastname').value
      const email = this.contactForm.get('email').value
      const phone = this.contactForm.get('phone').value
      const subject = this.contactForm.get('subject').value
      const message = this.contactForm.get('message').value

      const body = new HttpParams()
        .set('firstname', firstname)
        .set('lastname', lastname)
        .set('email', email)
        .set('phone', phone)
        .set('subject', subject)
        .set('message', message);


      await this.sendEmail(body);

    }

  }

  openDialog(statusCode: any): void {

    let resTxt = "Fehler";
    let infTxt = "Leider konnte Ihre Nachricht nicht übermittelt werden. Bitte versuchen Sie erneut oder senden Sie uns eine Email."

    if (statusCode === 200) {
      resTxt = "Erfolgreich";
      infTxt = "Ihre Nachricht wurde erfolgreich übermittelt."
    }

    const dialogRef = this.dialog.open(ModalDialogOverview, {
      width: '250px',
      data: { resultText: resTxt, infoText: infTxt }
    });

    dialogRef.afterClosed().subscribe(result => {

      if (statusCode === 200) {
        this.contactForm.reset();
      }

    });

  }

}


