import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from 'src/app/shared/services/config.service';

@Component({
  selector: 'app-careers-page',
  templateUrl: './careers-page.component.html',
  styleUrls: ['./careers-page.component.css']
})
export class CareersPageComponent implements OnInit {
  longText = `The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog
  from Japan. A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was
  originally bred for hunting.`;

  careers$: Observable<any> = new Observable();
  jobs$: Observable<any> = new Observable();
  // page_bullet_points$: Observable<any> = new Observable();

  constructor(private config: ConfigService) {}

  ngOnInit() {
    this.getPageData("pages", 14);
    this.getBlockData("jobs");
    // this.getBulletPoints("page_bullet_points", 7);
  }

  getPageData(database: string, id?: number) {
    this.careers$ = this.config.getSettings(database, id);
  }

  getBlockData(database: string) {
    this.jobs$ = this.config.getSettings(database);
  }
  // getBulletPoints(database: string, id?: number) {
  //   this.page_bullet_points$ = this.config.getSettings(database, id);
  // }


}
