import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CareersPageComponent } from './careers-page/careers-page.component';
import { CareersRoutingModule } from './careers-routing.module';
import { MatGridListModule } from '@angular/material/grid-list';

@NgModule({
  declarations: [
    CareersPageComponent
  ],
  imports: [
    CommonModule,
    CareersRoutingModule,
    MatGridListModule
  ]
})
export class CareersModule { }
