import { DOCUMENT } from "@angular/common";
import { Component, OnInit, Input, Inject, HostListener } from "@angular/core";

@Component({
  selector: "app-image-block",
  templateUrl: "./image-block.component.html",
  styleUrls: ["./image-block.component.css"],
})
export class ImageBlockComponent implements OnInit {
  @Input() image: { id: number; name: string; description: string, link: string };

  constructor(@Inject(DOCUMENT) private _document) { }

  ngOnInit() { }

  ngAfterViewInit() {
    // viewChild is set after the view has been initialized


  }




  // @HostListener('window:load')
  // onLoad() {
  //   console.log("timeout gefgfhghjjgghjsetzt");
  //   this.showSpinner();
  // }



  // showSpinner() {

  //   const images = this._document.querySelectorAll('img');
  //   let allImagesLoaded = this.checkImagesLoaded(images);

  //   while (allImagesLoaded.has(false)) {
  //     this.setDelay();
  //     console.log("allImagesLoaded has false = true");
  //     allImagesLoaded = this.checkImagesLoaded(images);
  //   }

  //   // if () {
  //   //   this.setDelay(1000);
  //   //   console.log("allImagesLoaded has false = true");
  //   // } else {
  //   //   console.log("allImagesLoaded are loaded");
  //   // }

  // }

  // checkImagesLoaded(images) {
  //   console.log("timeout sdsds");
  //   const allImagesLoaded = new Set();

  //   for (let i = 0; i < images.length; i++) {
  //     let img = images[i];
  //     let imgLoaded = img.complete && img.naturalHeight !== 0;
  //     allImagesLoaded.add(imgLoaded);
  //   }

  //   return allImagesLoaded;
  // }

  // setDelay() {
  //   setTimeout(function () {
  //     console.log("timeout gesetzt");
  //   }, 1000);
  // }

}
