import { Injectable } from "@angular/core";
import { InMemoryDbService } from "angular-in-memory-web-api";

@Injectable({
  providedIn: "root",
})
export class InMemoryDataService implements InMemoryDbService {
  constructor() { }
  createDb() {
    const pages = [
      {
        id: 1,
        name: "home",
        tagline: "SICHERHEIT",
        buttontext: "Leistungen",
        buttonlink: "/services",
        description:
          "Als Sicherheitsdienstleister im privaten Sicherheitsgewerbe bieten wir ein breites Spektrum an Sicherheitsdienstleistungen, technischer Komponenten sowie kompetente Beratung an. Dabei berücksichtigen wir individuell die jeweiligen Kundenbedürfnisse. Unser Team zeichnet sich durch hohe Kompetenz aus. Überzeugen Sie sich selbst!",

        title: "Über uns",
      },

      {
        id: 2,
        name: "contact",
        tagline: "YOUR CHOICE",
        title: "We have the right package for you",
        description: "Ihre Sicherheit in unseren Händen!",
      },
      {
        id: 3,
        name: "footer",
      },
      {
        id: 4,
        name: "services",
        tagline: "Ihr Sicherheit in unseren Kompetenzen",
        title: "Unser Leistungsspektrum",
        buttontext: "Kontakt",
        buttonlink: "/contact",
      },
      {
        id: 5,
        name: "impressum",
        title: "Impressum",
        buttontext: "Impressum",
        buttonlink: "/impressum",
      },
      {
        id: 6,
        name: "bodyguard",
        title: "Personenschutz",
        buttontext: "Kontakt",
        buttonlink: "/contact",
        bullets_title: "",
        bullet_points: []

      },
      {
        id: 7,
        name: "reception-porter-service",
        title: "Empfangs- und Pfortendienst",
        buttontext: "Kontakt",
        buttonlink: "/contact",
        bullets_title:
          "Wir bieten unseren Kunden Dienste wie Empfangs-, Hotelempfangs- und Pfortendienste mit folgenden möglichen Tätigkeiten:",
        bullet_points: [
          "Besucherausweis-Management",
          "Besucherbetreuung",
          "Besucherlenkung",
          "Betreuung des Fahrzeugpools",
          "Botendienste",
          "E-Mail-Service",
          "Handwerker- und Gärtner-Beauftragungen",
          "Hinweise auf betriebliche Hygienemaßnahmen",
          "Hinweise auf interne Besucherrichtlinien",
          "Hinweise auf interne Handwerkerrichtlinien",
          "Poststellendienst",
          "Schließdienste",
          "Schlüsselverwaltung",
          "Schulungsmanagement",
          "Telefondienst",
          "Überwachung der Video-Technik",
          "Überwachung von Gefahrenmeldeanlagen",
          "Zeiterfassung",
          "Zufahrtskontrollen",
          "Zutrittskontrollen",
        ],
      },
      {
        id: 8,
        name: "object-protection",
        title: "Objektschutz",
        buttontext: "Kontakt",
        buttonlink: "/contact",
        bullets_title: "",
        bullet_points: []
      },
      {
        id: 9,
        name: "mobile-security-services",
        title: "Mobile Sicherheitsdienste",
        buttontext: "Kontakt",
        buttonlink: "/contact",
        bullets_title: "",
        bullet_points: []
      },
      {
        id: 10,
        name: "city-patrol",
        title: "City-Streife",
        buttontext: "Kontakt",
        buttonlink: "/contact",
        bullets_title: "",
        bullet_points: []
      },
      {
        id: 11,
        name: "event-protection",
        title: "Eventschutz",
        buttontext: "Kontakt",
        buttonlink: "/contact",
        bullets_title:
          "Im Rahmen Eventschutz stehen unseren Kunden die nächsten Leistungen zur Verfügung:",
        bullet_points: [
          "Parkraummanagement",
          "Nachtwache",
          "Brandwache",
          "Standwache",
        ],
      },
      {
        id: 13,
        name: "data-protection",
        title: "Datenschutz"
      },
      {
        id: 14,
        description: "",
        name: "careers",
        title: "Jobs"
      },
    ];

    const images = [
      {
        id: 1,
        name: "gallery-image-1.jpg",
        description: "Empfangs- und Pfortendienst",
        link: "/services/reception-porter-service",
      },
      {
        id: 2,
        name: "gallery-image-2.jpg",
        description: "Personenschutz",
        link: "/services/bodyguard",
      },
      {
        id: 3,
        name: "gallery-image-3.jpg",
        description: "Eventschutz",
        link: "/services/event-protection",
      },
      {
        id: 4,
        name: "gallery-image-4.jpg",
        description: "Objektschutz",
        link: "/services/object-protection",
      },
      {
        id: 5,
        name: "gallery-image-5.jpg",
        description: "Mobile Sicherheitsdienste",
        link: "/services/mobile-security-services",
      },
      {
        id: 6,
        name: "gallery-image-6.jpg",
        description: "City-Streife",
        link: "/services/city-patrol",
      },
    ];

    const menu = [
      { id: 1, title: "Über uns", link: "/home" },
      { id: 2, title: "Leistungen", link: "/services" },
      // { id: 3, title: "Jobs", link: "/careers" },
      { id: 4, title: "Kontakt", link: "/contact" },
      { id: 5, title: "Impressum", link: "/impressum" },
    ];

    const websites = [
      {
        id: 1,
        link: "https://linkedin.com/",
        title: "LinkedIn",
        target: "_blank",
        username: "company/union-security-service/",
        icon: "linkedin-square",
      },
      {
        id: 2,
        link: "https://instagram.com/",
        title: "Instagram",
        target: "_blank",
        username: "union_security_service",
        icon: "instagram",
      },
    ];

    const jobs = [
      {
        id: 1,
        title: "Sicherheitsmitarbeiter",
        types: ["Vollzeit", "Teilzeit", "Minijob (450 €-basis)", "Studentenjob"],
        place: "verschiedene Städte",
        conditions: [
          "IHK Unterrichtung (34a Schein) oder IHK Sachkundeprüfung gem. § 34a GewO ",
          "Deutschkenntnisse",
          "Teamfähigkeit und Zuverlässigkeit",
          "Freundliches und gepflegtes Auftreten"
        ]
      }
    ];

    return {
      menu,
      pages,
      images,
      websites,
      jobs
    };
  }
}
