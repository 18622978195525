
import { Observable } from 'rxjs';
import { Component, OnInit } from "@angular/core";
import { ConfigService } from "../../../shared/services/config.service";

@Component({
  selector: 'app-bodyguard-page',
  templateUrl: './bodyguard-page.component.html',
  styleUrls: ['./bodyguard-page.component.css']
})
export class BodyguardPageComponent implements OnInit {

  bodyguard$: Observable<any> = new Observable();

  constructor(private config: ConfigService) {}

  ngOnInit() {
    this.getPageData("pages", 6);
  }

  getPageData(database: string, id?: number) {
    this.bodyguard$ = this.config.getSettings(database, id);
  }

}
