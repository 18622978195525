import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ServicesRoutingModule } from "./services-routing.module";
import { ServicesPageComponent } from "./services-page/services-page.component";
import { ImageBlockComponent } from "./image-block/image-block.component";

@NgModule({
  declarations: [ServicesPageComponent, ImageBlockComponent],
  imports: [CommonModule, ServicesRoutingModule],
})
export class ServicesModule {}
