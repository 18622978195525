import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ObjectProtectionPageComponent } from './object-protection-page/object-protection-page.component';

const routes: Routes = [
  { path: '', component: ObjectProtectionPageComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ObjectProtectionRoutingModule { }
