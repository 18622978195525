import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ReceptionPorterServicePageComponent } from './reception-porter-service-page/reception-porter-service-page.component';
import { ReceptionPorterServiceRoutingModule } from './reception-porter-service-routing.module';


@NgModule({
    declarations: [
      ReceptionPorterServicePageComponent
    ],
    imports: [
        CommonModule,
        ReceptionPorterServiceRoutingModule
    ]
})
export class ReceptionPorterServiceModule { }
