import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ObjectProtectionPageComponent } from './object-protection-page/object-protection-page.component';
import { ObjectProtectionRoutingModule } from './object-protection-routing.module';

@NgModule({
  declarations: [
    ObjectProtectionPageComponent
  ],
  imports: [
      CommonModule,
      ObjectProtectionRoutingModule
  ]
})
export class ObjectProtectionModule { }
