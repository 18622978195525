import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { MobileSecurityServicesPageComponent } from "./mobile-security-services-page/mobile-security-services-page.component";


const routes: Routes = [{ path: "", component: MobileSecurityServicesPageComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MobileSecurityServicesRoutingModule {}
