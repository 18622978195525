import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { CityPatrolPageComponent } from "./city-patrol-page/city-patrol-page.component";


const routes: Routes = [{ path: "", component: CityPatrolPageComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CityPatrolRoutingModule {}
